(function ($) {
  'use strict';

  // Avoid `console` errors in browsers that lack a console.
  (function() {
    var method;
    var noop = function () {};
    var methods = [
      'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
      'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
      'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
      'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
      method = methods[length];

      // Only stub undefined methods.
      if (!console[method]) {
          console[method] = noop;
      }
    }
  }());

  window.cookie = function (key, value, days) {
    var cookieObject = {},
        date,
        expires;

    if (document.cookie.length) {
        var cookies = document.cookie.split('; ');
        for (var i = 0; i < cookies.length; i++) {
            var keyvalue = cookies[i].split('=');
            cookieObject[keyvalue[0]] = unescape(keyvalue[1]);
        }
    }

    if (key && value) {
        if (days) { // If days is supplied, the cookie will not be a session cookie
            date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        }
        else expires = '';
        document.cookie = key + '=' + escape(value) + expires + '; path=/';
    }
    else if (key && value === null) { // Delete cookie
        date = new Date();
        date.setTime(date.getTime() - (1 * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
        document.cookie = key + '=' + expires + '; path=/';
    }
    else if (key) return cookieObject[key];
    else return cookieObject;
  };

  $(document).ready(function() {
    // Image Galery
    $('.thumbnails a').click(function(e) {
      e.preventDefault();

      var self = $(this);

      $('.detail').find('img').attr('src', self.data('detail'));
      $('.thumbnails a').removeClass('active');
      self.addClass('active');
    });

    // Language
    var language;

    if (!window.cookie('language')) {
      window.cookie('language', 'nl');
    }

    // Set active current language
    language = window.cookie('language');
    $('.' + language + '').addClass('active');

    // Set language on click
    $('.language a').click(function(e) {
      e.preventDefault();

      var self = $(this);

      window.cookie('language', self.text());
      $('.language a').removeClass('active');
      self.addClass('active');

      setLanguageText();
    });

    setLanguageText();
    function setLanguageText() {
      language = window.cookie('language');
      $('.text').hide();

      switch(language) {
        case 'nl':
          $('.text--nl').show();
        break;
        case 'fr':
          $('.text--fr').show();
        break;
        case 'en':
          $('.text--en').show();
        break;
        case 'de':
          $('.text--de').show();
        break;
        default:
          $('.text--nl').show();
      }

      $('.language').show();
    }
  });
})(window.jQuery);
